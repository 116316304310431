import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/features/Hero";
import DemoVideo from "../sections/features/DemoVideo";
import Features from "../sections/career/Features";
import HeaderButton from "../components/HeaderButton";

const FeaturesPage = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (<HeaderButton />),
          footerStyle: "style3",
        }}
      >
        <Hero />
        <DemoVideo />
        <Features />
      </PageWrapper>
    </>
  );
};
export default FeaturesPage;
